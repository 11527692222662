<template>
    <router-view :key="$route.fullPath"/>
</template>

<script>

export default {
  name: 'BoardAll'
  // beforeRouteEnter (to, from, next) {
  //   next()
  // }
}
</script>

<style scoped>
  td{
    white-space: normal !important;
  }
</style>
